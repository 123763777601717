import axios, { AxiosResponse, AxiosRequestConfig, Method } from "axios";

import { HttpResponse } from "types";
import { apis } from "./settings";

interface RequestParams {
  token: string;
  api: keyof typeof apis;
  path: string;
  body?: any;
}

async function request<T>({ token, method, api, path, body }: RequestParams & { method: Method }): Promise<HttpResponse<T>> {
  let url = `https://${apis[api]}${path}`;

  try {
    let requestConfig: AxiosRequestConfig = {
      method,
      timeout: 45000,
      url,
      data: body,
      headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
    };

    let requestResponse: AxiosResponse = await axios(requestConfig);

    return { data: requestResponse.data, isErrorResponse: () => false };
  } catch (error) {
    let errorText = "";
    let errorCode: number | undefined;

    if (axios.isAxiosError(error)) {
      // Axios error message
      if (error.response?.statusText) {
        errorText = error.response.statusText;
      }
      errorText = errorText || "An error occurred";
      errorCode = error.response?.status;
    }
    return { errorText, errorCode, isErrorResponse: () => true };
  }
}

export async function get<T>(params: RequestParams) {
  return await request<T>({ method: "get", ...params });
}
export async function post<T>(params: RequestParams) {
  return request<T>({ method: "post", ...params });
}
export async function put<T>(params: RequestParams) {
  return request<T>({ method: "put", ...params });
}
export async function patch<T>(params: RequestParams) {
  return request<T>({ method: "patch", ...params });
}
export async function del<T>(params: RequestParams) {
  return request<T>({ method: "delete", ...params });
}
