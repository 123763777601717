import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "normalize.css/normalize.css";

import { Suspense } from "react";
import { createRoot } from "react-dom/client";

import { ThemeProvider } from "@mui/material/styles";

import "i18n";

import App from "./App";
import "assets/styles/index.scss";

import Spinner from "components/ui/Spinner";
import ErrorBoundary from "components/ErrorBoundary";

import theme from "mui";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <ErrorBoundary>
    <Suspense fallback={<Spinner />}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Suspense>
  </ErrorBoundary>
);
