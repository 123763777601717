import { createTheme } from "@mui/material/styles";

let theme = createTheme({
  breakpoints: {
    values: { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1500 },
  },
  palette: {
    primary: {
      main: "#6B75FA",
    },
    secondary: {
      main: "#292E42",
    },
  },
  typography: {
    fontFamily: '"Gotham Rounded", sans-serif',
  },
});

export default theme;
