import { useContext, useState } from "react";

import { useTranslation } from "react-i18next";

import { FormControl, FormHelperText, TextField } from "@mui/material";

import Button from "components/ui/Button";
import Spinner from "./ui/Spinner";

import { AppContext } from "utils/context";
import { get, post } from "utils/http";
import { Customer, CustomerToken } from "types";

export default function CreateCustomerForm() {
  const { superAdminToken, setCustomer } = useContext(AppContext);

  const { t } = useTranslation();

  let [isLoading, setIsLoading] = useState(false);
  let [inputValue, setInputValue] = useState("");

  function handleSaveError(createdCustomer: Customer) {
    setIsLoading(false);
    setCustomer(createdCustomer);
  }

  async function handleSaveClick() {
    if (!superAdminToken || !inputValue) {
      return;
    }

    let customerName = inputValue;
    setIsLoading(true);

    let customerResponse = await post<{ id: string; name: string }>({ token: superAdminToken, api: "AUTH", path: "/customer", body: { name: inputValue } });
    if (customerResponse.isErrorResponse()) {
      // Critical error - could not create customer
      setIsLoading(false);
      throw new Error(`Fejl i oprettelse af ${customerName}`);
    }

    let customerId = customerResponse.data.id;

    let createdCustomer: Customer = {
      customerId,
      token: "",
      username: "Kunne ikke oprettes",
      password: "Kunne ikke oprettes",
    };

    // Creating tokens
    await Promise.all([
      get<void>({ token: superAdminToken, api: "AUTH", path: `/customertoken/createcustomertoken/${customerId}` }),
      get<void>({ token: superAdminToken, api: "AUTH", path: `/customertoken/createcustomeradmintoken/${customerId}` }),
    ]);

    // Then fetching all tokens for the customer
    let customerTokenResponse = await get<CustomerToken[]>({ token: superAdminToken, api: "AUTH", path: `/customertoken/${customerId}` });
    if (customerTokenResponse.isErrorResponse()) {
      return handleSaveError(createdCustomer);
    }

    let customerToken = customerTokenResponse.data.find((token) => token.type === "Admin")?.token;
    if (!customerToken) {
      return handleSaveError(createdCustomer);
    }
    createdCustomer.token = customerToken;

    // Lastly, creating login credentials
    let username = customerName.split(" ").join("_");
    let password = Math.random().toString(36).slice(-8);
    let customerUserResponse = await post<void>({ token: superAdminToken, api: "AUTH", path: "/user", body: { customerId, username, password, role: "User" } });
    if (customerUserResponse.isErrorResponse()) {
      return handleSaveError(createdCustomer);
    }
    createdCustomer.username = username;
    createdCustomer.password = password;

    // And language settings
    await post<void>({ token: customerToken, api: "CUSTOMERSETTING", path: "/language", body: { defaultLanguage: "da-DK", languages: ["da-DK"] } });

    setIsLoading(false);
    setCustomer(createdCustomer);
  }

  return (
    <div className="form">
      <h2>{t("Create.Title")}</h2>
      <FormControl>
        <TextField fullWidth variant="outlined" type="text" onChange={(e) => setInputValue(e.target.value)} label={t("Create.CustomerName")} />
        <FormHelperText>{t("Create.CustomerNameTooltip")}</FormHelperText>
      </FormControl>
      <Button disabled={!inputValue || isLoading} onClick={handleSaveClick}>
        {t("Create.ButtonAction")}
      </Button>

      {isLoading && <Spinner />}
    </div>
  );
}
