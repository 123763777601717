import { Dispatch, SetStateAction, createContext } from "react";
import { Customer } from "types";

export interface AppContextType {
  superAdminToken: string | null;
  setSuperAdminToken: Dispatch<SetStateAction<string | null>>;

  customer: Customer | null;
  setCustomer: Dispatch<SetStateAction<Customer | null>>;
}

const defaultContext: AppContextType = {
  superAdminToken: null,
  setSuperAdminToken: (): void => {},

  customer: null,
  setCustomer: (): void => {},
};

export const AppContext = createContext<AppContextType>(defaultContext);
