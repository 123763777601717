import { useContext } from "react";
import { useTranslation } from "react-i18next";

import Button from "./ui/Button";

import { AppContext } from "utils/context";

import "./Summary.scss";

export default function CustomerSummary() {
  const { customer } = useContext(AppContext);

  const { t } = useTranslation();

  function handleButtonClick(token: string) {
    window.open(`https://customersetup.crunchorder.com?token=${token}`, "_blank");
  }

  if (!customer) {
    return <></>;
  }

  const { token, username, password } = customer;
  return (
    <div className="summary">
      <h2>{t("Summary.Title")}</h2>
      <p className="summary-text">
        <span className="summary-label">{t("Summary.Username")}:</span>
        <br />
        {username}
      </p>
      <p className="summary-text">
        <span className="summary-label">{t("Summary.Password")}:</span>
        <br />
        {password}
      </p>

      {!!token && <Button onClick={() => handleButtonClick(token)}>{t("Summary.ButtonAction")}</Button>}
    </div>
  );
}
