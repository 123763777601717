import { useState } from "react";

import AppContent from "components/Content";

import { Customer } from "types";
import { AppContext } from "utils/context";
import { localStore } from "utils/storage";

import logo from "assets/img/logo.png";

export default function App() {
  let [superAdminToken, setSuperAdminToken] = useState<string | null>(localStore.get("appToken") || null);
  let [customer, setCustomer] = useState<Customer | null>(null);

  return (
    <div className="main page">
      <img className="logo" src={logo} alt="logo" />
      <div className="page-content">
        <AppContext.Provider
          value={{
            superAdminToken,
            setSuperAdminToken,
            customer,
            setCustomer,
          }}
        >
          <AppContent />
        </AppContext.Provider>
      </div>
    </div>
  );
}
