import { useContext } from "react";

import TokenForm from "./Token";
import CustomerSummary from "components/Summary";
import CreateCustomerForm from "components/Create";

import { AppContext } from "utils/context";

export default function AppContent() {
  const { superAdminToken, customer } = useContext(AppContext);

  if (!superAdminToken) {
    return <TokenForm />;
  }

  if (!customer) {
    return <CreateCustomerForm />;
  }

  return <CustomerSummary />;
}
