import { useContext, useState } from "react";

import { useTranslation } from "react-i18next";

import { FormControl, TextField } from "@mui/material";

import Button from "components/ui/Button";

import { AppContext } from "utils/context";
import { localStore } from "utils/storage";

export default function TokenForm() {
  const { setSuperAdminToken } = useContext(AppContext);

  const { t } = useTranslation();

  let [inputValue, setInputValue] = useState("");

  async function handleButtonClick() {
    localStore.save("appToken", inputValue);
    setSuperAdminToken(inputValue);
  }

  return (
    <div className="form">
      <h2>{t("Token.Title")}</h2>
      <FormControl>
        <TextField fullWidth variant="outlined" type="text" onChange={(e) => setInputValue(e.target.value)} label={t("Token.TokenType")} />
      </FormControl>
      <Button disabled={!inputValue} onClick={handleButtonClick}>
        {t("Token.ButtonAction")}
      </Button>
    </div>
  );
}
